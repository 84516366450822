import React from "react";
import { Link as RemixLink, useNavigation } from "@remix-run/react";
import {
  Box,
  VStack,
  Flex,
  Text,
  Link,
  Heading,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

import { MdVolumeUp } from "react-icons/md";

const clips = ["eira.m4a", "justin.m4a", "dan.m4a"];

export function DefinitionPopover({ trigger }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigation = useNavigation();

  // Close popover if the FAQ link is clicked
  React.useEffect(() => {
    onClose();
  }, [navigation]);

  return (
    <Popover
      placement="bottom-start"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <PopoverTrigger>{trigger}</PopoverTrigger>
      <PopoverContent fontSize="md" fontWeight="normal" textAlign="left">
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>
          <Text>
            What's a <Text as="em">schnerp</Text>?
          </Text>
        </PopoverHeader>
        <PopoverBody>
          <Definition />
        </PopoverBody>
        <PopoverFooter>
          <Text fontSize="sm">
            Still confused? Head to the{" "}
            <Link as={RemixLink} to="/faq">
              FAQ page
            </Link>{" "}
          </Text>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

export function Definition(rest) {
  const [audios, setAudios] = React.useState([]);
  const [audioIndex, setAudioIndex] = React.useState(0);

  React.useEffect(() => {
    setAudios(clips.map((audio) => new Audio("/audio/" + audio)));
  }, []);

  const playAudio = () => {
    const audio = audios[audioIndex];
    audio.play();
    setAudioIndex(audioIndex === audios.length - 1 ? 0 : audioIndex + 1);
  };

  return (
    <VStack align="flex-start" {...rest}>
      <Flex align="center" justify="space-between">
        <IconButton
          aria-label="Hear pronunciation"
          variant="secondary"
          isRound
          icon={<MdVolumeUp fontSize="24px" />}
          mr={2}
          onClick={playAudio}
        />
        <Text fontSize="xl">sh·NER·puh</Text>
      </Flex>
      <Text as="em" color="subtle">
        verb
      </Text>
      <Box pl={4}>
        <Text>To acquire a scarce resource, usually by a thin margin</Text>
        <Text color="subtle">
          "How did you schnerp Lake O'Hara?! I thought was sold out all summer?"
        </Text>
      </Box>
    </VStack>
  );
}
